<template>
	<main>
        <section class="mv">
            <p class="mv__blur">&nbsp;</p>
            <p class="mv__dept">
                <span>精神科</span>
                <span>神経科</span>
                <span>心療内科</span>
                <span>神経内科</span>
            </p>
            <div class="mv__headCopy">
                <h2>鳥には空を、魚には水を、<br>そして人には社会を。</h2>
                <p>こころの病は、治療を受けるのが遅くなりがち。<br>
                うつ病や双極性障害のような気分障害、<br>不安障害やパニック障害、統合失調症など、<br>
                こころの病でお悩みの方が気軽に受診できる<br>クリニックを目指しています。</p>
            </div>
            <!-- ビジュアルナビ -->
            <div class="mv__visualNav">
                <!-- 院内施設案内 -->
                <div class="mv__visualNav--hospital">
                <h3>院内施設案内<span>Hospital facility information</span></h3>
                <p>クリニック内の施設・設備をご案内します。</p>
                <router-link to="/hospital">&nbsp;</router-link>
                </div>
                <!-- デイケアセンター -->
                <div class="mv__visualNav--dayCare">
                <h3>デイケアセンター<span>Day Care Center</span></h3>
                <p>こころの病を抱える方が、充実して生活できるように。</p>
                <router-link to="/daycare_nightcare">&nbsp;</router-link>
                </div>
                <!-- 訪問看護 -->
                <div class="mv__visualNav--visitNursing">
                <h3>訪問看護<span>Psychiatric visit nursing</span></h3>
                <p>看護師や精神保健福祉士がお住まいを訪問して支援。</p>
                <router-link to="/visiting_nursing">&nbsp;</router-link>
                </div>
                <!-- 治療療育プログラム -->
                <div class="mv__visualNav--counseling">
                <h3>カウンセリング<span>Treatment and care program</span></h3>
                <p>臨床心理士による心の健康問題のカウンセリング。</p>
                <router-link to="/counseling">&nbsp;</router-link>
                </div>
            </div>
        </section>
        <!-- INFORMATIONS -->
        <section class="informations">
            <h2>INFORMATIONS</h2>

            <h3>＜4月の診療情報＞</h3>
            <dl>
                <!-- <dd>連休期間も含め、今月はカレンダー通りの診療時間となります。</dd> -->
                <dt>休診のお知らせ</dt>
                <!-- <dd>
                    <h4 style="margin-top:0;">年末年始の休診期間</h4>
                    <p>令和６年１２月２８日（土）午後から令和７年１月３日（金）まで</p>
                </dd> -->
                <dd>
                特にありません。
                </dd>

                <dt>新規患者様受付について</dt>
                <dd>
                    <p>予約待ちが100名を超えましたので、<span style="text-decoration:underline;">当面新規患者様の受付を中止いたします。</span><br>
                      しばらくの間ご迷惑をお掛けしますが、よろしくお願いいたします。</p>
                </dd>

                <dt>各種「予防接種」のおしらせ</dt>
                <dd>
                    <!-- <h4 style="margin-top:0;">インフルエンザ予防接種</h4>
                    <table>
                        <tr>
                            <th style="white-space: nowrap; vertical-align: top;">開始日</th>
                            <td style="white-space: nowrap; vertical-align: top;">&thinsp;：&thinsp;</td>
                            <td>10月１日（火）より接種可能です。</td>
                        </tr>
                        <tr>
                            <th style="white-space: nowrap; vertical-align: top;"><h4 style="margin:0; color:#000;">料金</h4></th>
                            <td style="white-space: nowrap; vertical-align: top;">&thinsp;：&thinsp;</td>
                            <td>
                                <table>
                                    <tr>
                                        <td style="vertical-align: top;">高齢者&emsp;1,200円（郡山市在住の方）&nbsp;市外の方は各市町村に確認してください。</td>
                                    </tr>
                                    <tr>
                                        <td>一&emsp;般&emsp;4,400円</td>
                                    </tr>
                                </table>  
                            </td>
                        </tr>
                    </table> -->
                    <h4 style="margin-top:0;">新型コロナウィルス予防接種</h4>
                    <table>
                        <tr>
                            <td><h4 style="margin:0; color:#000;">料金</h4></td>
                            <td>&thinsp;：&thinsp;</td>
                            <td>高齢者</td>
                            <td>&emsp;</td>
                            <td>2,100円</td>
                        </tr>
                        <tr>
            
            
                            <td>一&emsp;般</td>
                            <td>&emsp;</td>
                            <td>15,400円&nbsp;※税込価格</td>
                        </tr>
                    </table>
                    <p>上記、<strong class="txt-pink">予防接種共に事前に予約が必要となります</strong>。受付にて予約を受け付けますので、ご連絡ください。</p>
                </dd>

            <!-- <h3>＜２月の診療情報＞</h3>
                <dt>年末年始休診期間のお知らせ</dt>
                <dd><strong class="txt-pink">令和５年12月28日（木）～令和６年１月３日（水）</strong></dd>
                
                <dt>インフルエンザ予防接種</dt>
                <dd>
                    <p><strong>10月2日（月曜日）より</strong>開始しています。</p>
                    <table>
                        <tr>
                            <th colspan="3">接種料金</th>
                        </tr>
                        <tr>
                            <th style="white-space: nowrap; vertical-align: top;">高齢者</th>
                            <td style="white-space: nowrap; vertical-align: top;">&thinsp;：&thinsp;</td>
                            <td>1,200円（郡山市在住の65歳以上の方）12/28（木）まで</td>
                        </tr>
                        <tr>
                            <th style="white-space: nowrap; vertical-align: top;">一般</th>
                            <td style="white-space: nowrap; vertical-align: top;">&thinsp;：&thinsp;</td>
                            <td>4,400円（4,000円＋消費税400円）</td>
                        </tr>
                        
                    </table>
                    <p style="padding-left:1em; text-indent:-1em;">※接種を希望される方は、<span class="txt-pink">事前に電話にて予約</span>をお取りください。</p>
                </dd> -->

                <!-- <dt>コロナウィルスワクチン接種再開について</dt>
                <dd>
                    当院で以前接種された方を対象に、コロナウィルスワクチン接種を<em>「令和５年６月より再開」</em>しています。<br>
                    接種をご希望の方は、接種券がお手元に届きましたら、<em>「お電話にて予約」</em>をお取りください。
                    <table>
                        <tbody>
                            <tr>
                                <th colspan="2">電話予約受付時間</th>
                            </tr>
                            <tr>
                                <td colspan="2">月・火・木・金曜日&emsp;&emsp;9：00&nbsp;～&nbsp;17：00</td>
                            </tr>
                            <tr>
                                <th colspan="2">接種当日の持ち物</th>
                            </tr>
                            <tr>
                                <td style="vertical-align:top;">１．</td>
                                <td>本人確認書類<br>※運転免許証、健康保険証、パスポート、年金手帳等</td>
                            </tr>
                            <tr>
                                <td style="vertical-align:top;">２．</td>
                                <td>接種券一体型予診票<br>※予診票には事前にご自身で記入してください。</td>
                            </tr>
                            <tr>
                                <td style="vertical-align:top;">３．</td>
                                <td>お薬手帳（あれば）</td>
                            </tr>
                        </tbody>
                    </table>
                </dd> -->
                
                <!-- <dt>休診のお知らせ</dt>
                <dd><span class="txt-pink">７月３１日（土）午後の診療</span>：鈴木章市先生、鈴木実穂先生、研修会参加の為午後休診。</dd> -->

                <!-- <dt>休診のお知らせ</dt>
                <dd>
                    お盆休み期間<br>
                    <span class="txt-pink">令和3年8月15日（日）から18日（水）まで</span><br>
                    ※お薬のもらい忘れのないよう、ご注意ください。
                </dd> -->

                <!--<dt>コロナウィルスワクチン接種について</dt>
                <dd>
                    <p>受付は終了いたしました。</p>
                    <ul>
                        <li>接種券をお持ちの方<br>※事前にお電話にて下記の日時に予約をお取りください。</li>
                    </ul>
                    <h4>[新型コロナウィルスワクチン接種予約対応]</h4>
                    <p>月・火・木・金&emsp;PM2：00～PM4：30まで。&emsp;TEL.024-966-3300（代表電話）</p>
                    <p>また、<span class="txt-pink">他院（内科等）に受診されている方は、必ず接種可能か確認してから予約をお取りください</span>。ご不明な点等ございましたら、上記時間にお問い合わせください。</p>
                    <p>当院ではコロナウィルスワクチン接種を開始しております。<br>現在の接種対象者は当院がかかりつけ医である郡山市居住の65歳以上の方となっております。<span class="txt-pink">必ず、お電話にて予約をお取りください。</span></p>
                    <table>
                        <tbody>
                            <tr>
                                <th>予約受付可能日時</th>
                                <td>：</td>
                                <td>月・火・木・金曜日14：00～16：30まで</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>※今後接種対象者を拡大していく予定です。</p>
                </dd>-->

                <dt>風邪症状（発熱等）で受診される方へ<span class="txt-pink">【重要】</span></dt>
                <dd>直接来院される前に、まずお電話でご連絡を頂くようにお願い致します。</dd>
                <!-- <dt>インフルエンザ予防接種のお知らせ</dt>
                <dd>インフルエンザ予防接種…10月1日（木）より開始しています。<br>※ご希望の方は、必ず事前にお電話で予約をお取りください。</dd> -->
                <!-- <dt>休診時間のお知らせ</dt>
                <dd>
                    <table>
                        <tr>
                            <th>4月17日（土）&nbsp;午後のみ休診</th>
                            <td>&nbsp;</td>
                            <td>鈴木章市先生&emsp;鈴木実穂先生</td>
                        </tr>
                    </table>
                </dd> -->

                <!-- <dt>新患受付に関して<span class="txt-pink">【重要】</span></dt>
                <dd>新患ご予約の方が現在100名を超えており、現在新患受付のみ一時停止させて頂いております。再開しましたらこちらトップページ等で発表いたします。ご了承下さいませ。</dd> -->
                <dt>入院について<span class="txt-pink">【重要】</span></dt>
                <dd>令和5年11月30日をもちまして入院病棟を「廃止」しました。</dd>
                <!-- <dt>４月１６日（月）より、当院外壁補修工事が行われます。</dt>
                <dd>ご迷惑をお掛けしますがご協力お願いいたします。</dd> -->
                <!-- <dt><span class="txt-blue">平成27年5月</span>より、当院デイケアの体制が変わっております。</dt>
                <dd>※詳細、お問合せはクリニックまでお電話ください。</dd> -->
                <dt>当院西側駐車場の夜間および休日の閉鎖について防犯のため西側駐車場は下記の通り閉鎖しております。</dt>
                <dd>
                <table>
                    <tbody>
                    <tr>
                        <td>夜間（月・水・木・土）</td>
                        <td>：</td>
                        <td>19：00～6：00</td>
                    </tr>
                    <tr>
                        <td>夜間（火・金）</td>
                        <td>：</td>
                        <td>21：00～6：00</td>
                    </tr>
                    <tr>
                        <td>休診日</td>
                        <td>：</td>
                        <td>終日</td>
                    </tr>
                    </tbody>
                </table>
                <p>尚、上記日時に御来院の方は病棟入口前駐車場をご利用ください。</p>
                </dd>
                <p><a href="./img/daycare_calendar.pdf?d=050701" target="_blank" rel="nofollow noopener noreferrer"><img src="../../assets/daycare_bnr.png" alt="デイケアカレンダーはこちら" srcset=""></a></p>
                <dd class="kakomi">
                <h3>＜求人情報＞</h3>
                <table>
                    <tbody>
                        <tr>
                            <td>公認心理師（取得見込可）</td>
                            <td>常勤（非常勤も可）&emsp;1名</td>
                        </tr>
                        <tr>
                            <td>看護師（正・准）</td>
                            <td>常勤（非常勤も可）&emsp;1名</td>
                        </tr>
                        <!-- <tr>
                            <td>精神保健福祉士</td>
                            <td>常勤（非常勤も可）&emsp;1名</td>
                        </tr> -->
                        <tr>
                            <td>介護福祉士</td>
                            <td>常勤（非常勤も可）&emsp;1名</td>
                        </tr>
                    </tbody>
                </table>
                <p>※詳しくは当クリニックまでお電話でお問い合わせください。</p>
                </dd>
            </dl>
        </section>
        <!-- SERVICES -->
        <section class="services">
            <div>
                <!-- 子どもから成人の診療 -->
                <div class="service">
                <h2>子どもから成人の診療</h2>
                <div>
                    <p class="photo"><img src="../../assets/ph_top_services_01.png" alt=""></p>
                    <p>成人の他、自閉症や多動性障害などの発達障害、不登校、てんかんなどの子どもの診療も行っております。</p>
                </div>
                </div>
                <!-- 夜間診療 -->
                <div class="service">
                <h2>夜間診療</h2>
                <div>
                    <p class="photo"><img src="../../assets/ph_top_services_02.png" alt=""></p>
                    <p>当院では毎週火曜日、金曜日に18時～20時まで夜間診療を行っております。</p>
                </div>
                </div>
                <!-- アットホームな環境 -->
                <div class="service">
                <h2>アットホームな環境</h2>
                <div>
                    <!-- <p class="photo"><img src="../../assets/ph_top_services_03.png" alt=""></p> -->
                    <p>患者さんが安心して来院できるようアットホームな環境づくりを目指しております。</p>
                </div>
                </div>
                <!-- 女性医師が在籍 -->
                <div class="service">
                <h2>女性医師が在籍</h2>
                <div>
                    <p class="photo"><img src="../../assets/ph_top_services_04.png" alt=""></p>
                    <p>当院では女性医師が在籍しており、女性の患者さんにも安心して来院いただくことができます。</p>
                </div>
                </div>

                <section class="services__info">
                    <div class="cols2">
                        <p>うつ病や不安障害、統合失調症などの精神疾患、多動性障害や自閉スペクトラム症などのいわゆる発達障害の方の診察や治療の他、短期入院治療も行っています。</p>
                        <p class="forBeginers"><router-link to="/for_beginners">初めての方へ</router-link></p>
                        <h3>総合診療時間</h3>
                        <table>
                            <!-- <caption>総合診療時間</caption> -->
                            <thead>
                                <tr>
                                    <th>診療時間</th>
                                    <th>月</th>
                                    <th>火</th>
                                    <th>水</th>
                                    <th>木</th>
                                    <th>金</th>
                                    <th>土</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>9:00～12:00</td>
                                <td>●</td>
                                <td>●</td>
                                <td>&nbsp;</td>
                                <td>●</td>
                                <td>●</td>
                                <td>●</td>
                            </tr>
                            <tr>
                                <td>2:00～ 5:00</td>
                                <td>●</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>●</td>
                                <td>&nbsp;</td>
                                <td>●</td>
                            </tr>
                            <tr>
                                <td>6:00～ 8:00</td>
                                <td>&nbsp;</td>
                                <td>●</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>●</td>
                                <td>&nbsp;</td>
                            </tr>
                            </tbody>
                        </table>
                        <p class="attention"><router-link to="/outpatient">※各担当医の個別診療時間はこちら</router-link></p>
                    </div>
                        
                    <div class="cols2">
                    <p><img src="../../assets/access_map.svg" alt=""></p>
                    <p class="attention"><a href="https://goo.gl/maps/ST2B5Z6mxhGowt1h6" target="_blank" rel="noopener noreferrer">Googleマップを表示</a></p>
                    </div>
                </section>
            </div>
        </section>
    </main>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Meta from '../mixins/MetaSettable'

export default {
	name: 'Home',
	mixins: [Meta],
	data() {
		return {
			isLoading: true,
			loadedCount: 0,
			cnt: 0,
			appClass: 'home'
		}
	},
	mounted() {
        // this.cnt = document.images.length;
        this.cnt = document.querySelectorAll('main img').length;
		if(this.cnt == 0){
			this.isLoading = false;
		}
		this.$emit('appClassMethod', this.appClass);
	},
	computed: {
		currentCount(){
		return this.$store.getters.currentCount;
		}
	},
	methods: {
		loaded () {
			this.loadedCount++;
			// console.log(this.loadedCount);;
			if(this.loadedCount === this.cnt) {
				this.isLoading = false;
			}
		}
	}
}
</script>